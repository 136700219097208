<template>
    <div id="FoundAppleRedPacket">
        <div class="filter-panel ">
            <CSSelect style="width: 230px; margin-right: 0;">
                <el-date-picker
                    v-model="queryParams.timeStart"
                    type="datetime"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm"
                    placeholder="请选择开始时间"
                    :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <span class="inline-gray"></span>
            <CSSelect style="margin: 0 20px 15px 0; width: 230px">
                <el-date-picker
                    v-model="queryParams.timeEnd"
                    type="datetime"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm"
                    placeholder="请选择结束时间"
                    :editable="false"
                >
                </el-date-picker>
            </CSSelect>

            <CSSelect style="margin-right: 20px; width: 138px">
                <select
                    class="form-control typeinput"
                    :style="{
                                        color:
                                            queryParams.state != ''
                                                ? '#000'
                                                : '#999',
                                    }"
                    v-model="queryParams.type"
                >
                    <option value="">可抢人群不限</option>
                    <option value="1">所有人</option>
                    <option value="2">专属</option>
                </select>
            </CSSelect>

            <CSSelect style="margin-right: 20px; width: 138px">
                <select
                        class="form-control typeinput"
                        :style="{
                                        color:
                                            queryParams.state != ''
                                                ? '#000'
                                                : '#999',
                                    }"
                        v-model="queryParams.manner"
                >
                    <option value="">分配方式不限</option>
                    <option value="1">随机</option>
                    <option value="2">平均</option>
                </select>
            </CSSelect>

            <CSSelect style="margin-right: 20px; width: 138px">
                <select
                        class="form-control typeinput"
                        v-model="queryParams.state"
                        :style="{
                                        color:
                                            queryParams.state != ''
                                                ? '#000'
                                                : '#999',
                                    }"
                >
                    <option value="">
                        状态不限
                    </option>
                    <option
                            v-for="(
                                            stateName, key
                                        ) in appleStateMapping"
                            :key="key"
                            :value="key"
                            style="color: #000"
                    >
                        {{ stateName }}
                    </option>
                </select>
            </CSSelect>
            <input
                type="text"
                placeholder="搜索红包名称/备注"
                class="cs-input"
                style="margin-left: 0; margin-right: 30px;"
                v-model="queryParams.keyword"
            />
            <button
                type="button"
                class="btn btn-primary"
                @click="getAppleRedPacketList()"
            >
                查询
            </button>
        </div>

        <div class="result-panel">
            <CSTable :thead-top="filterHeight">
                <template v-slot:header>
                    <div
                        class="table-header-panel text-right"
                        style="border-bottom: 1px solid #ddd; padding: 15px 30px;"
                    >
                        <button
                            type="button"
                            class="btn btn-primary sticky-right"
                            @click="openCreateAppleRedPacketDialog"
                        >
                            <svg
                                class="icon"
                                aria-hidden="true"
                            >
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            创建苹果红包
                        </button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>添加时间</th>
                        <th>苹果红包名称</th>
                        <th>红包个数</th>
                        <th>可抢人群</th>
                        <th>可抢人数</th>
                        <th>分配方式</th>
                        <th>截止时间</th>
                        <th>备注</th>
                        <th>状态</th>
                        <th>操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>

                    <template
                        v-if="appleRedpacketActivities.length > 0"
                    >
                        <tr
                            v-for="activity in appleRedpacketActivities"
                            :key="activity.id"
                        >
                            <td class="date-td">
                                {{
                                    activity.createTime ||
                                    '-'
                                }}
                            </td>
                            <td>
                                {{ activity.name || '-' }}
                            </td>
                            <td>
                                {{ activity.amount }}
                            </td>
                            <td>
                                {{activity.type == 1?'所有人':activity.type == 2?'专属：'+activity.userInfo || '-':'-'}}
                            </td>
                            <td>
                                {{
                                    activity.shares || '-'
                                }}
                            </td>
                            <td>
                                {{activity.manner == 1?'随机':activity.manner == 2?'平均':'-'}}
                            </td>
                            <td>
                                {{ activity.expiredTime }}
                            </td>
                            <td>
                                <span v-if="activity.comment" class="allow-click"
                                      @click="lookComment(activity.comment)">
                                    查看
                                </span>
                                <span v-else>-</span>
                            </td>
                            <td>
                                {{
                                    appleStateMapping[
                                        activity.state
                                        ] || '-'
                                }}
                            </td>
                            <td>
                                <div class="btn-group">
                                    <button
                                        type="button"
                                        class="btn btn-primary dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        操作
                                    </button>
                                    <ul
                                        class="dropdown-menu"
                                        style="
                                                                width: 104px;
                                                            "
                                    >
                                        <li>
                                            <a
                                                target="_blank"
                                                :href="
                                                                        downloadFileOssDomain +
                                                                        activity.qrCodePath
                                                                    "
                                                style="text-decoration: none;"
                                            >
                                                下载二维码
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                @click="
                                                                        openChangeActivityDialog(
                                                                            activity
                                                                        )
                                                                    "
                                            >
                                                二维码
                                                {{
                                                    activity.state ===
                                                    3
                                                        ? "生效"
                                                        : "失效"
                                                }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template v-else></template>
                </template>
            </CSTable>


            <Pagination/>
        </div>

        <ViewModal/>

        <!-- 添加苹果红包的弹出框 -->
        <CSDialog
            :dialogVisible="createAppleRedPacketVisible"
            dialogTitle="创建苹果红包"
            dialogWidth="960px" :is-submitting="isSubmitting"
            @onClose="createAppleRedPacketVisible = false"
            @onConfirm="createAppleRedPacket"
            @onCancel="createAppleRedPacketVisible = false"
        >
            <div slot="dialog-content" style="padding: 20px 30px 0 30px;">
                <div class="AppleRedPacket_box">
                    <span>苹果红包名称</span>
                    <i>
                        <input
                            type="text"
                            placeholder="限20个字"
                            v-model="addRedPacketParams.name"
                        />
                    </i>
                </div>
                <div class="AppleRedPacket_box">
                    <span>苹果数量</span>
                    <i>
                        <input
                            type="text"
                            style="width: 102px"
                            placeholder="1-9999"
                            v-model="addRedPacketParams.amount"
                        />
                        个
                    </i>
                </div>
                <div class="AppleRedPacket_box">
                    <span>可抢人群</span>
                    <i>
                        <CSRadio v-model="addRedPacketParams.type" :items="{1: '所有人', 2: '专属'}"/>
                    </i>
                </div>
                <div class="AppleRedPacket_box" v-if="addRedPacketParams.type != 2">
                    <span>可抢人数</span
                    ><i
                ><input
                    type="text"
                    style="width: 102px"
                    placeholder="1-9999"
                    v-model="addRedPacketParams.shares"
                />
                    人</i
                >
                </div>
                <div class="AppleRedPacket_box" v-if="addRedPacketParams.type != 2">
                    <span>分配方式</span>
                    <i>
                        <CSRadio v-model="addRedPacketParams.manner" :items="{1: '随机', 2: '平均'}"/>
                    </i>
                </div>
                <div class="AppleRedPacket_box" v-if="addRedPacketParams.type == 2" style="padding-bottom: 40px">
                    <span style="float: left">选择人员</span>
                    <div style="width: 347px;float: left" >
                        <ChooseLocation
                                mission="deputy"
                                style="--readonly-input-width: 307px"
                                @changeLocation="changeLocation"
                                :showInput="false"
                                :isBangBang="true"
                                :defaultLocationName = 'defaultLocationName'
                        />
                        <div style="font-size: 20px; color: #999;display: block;margin-bottom: 10px;margin-top: 5px">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-menua-zu92"></use>
                            </svg>
                            只显示已注册棒棒我鸭用户。
                        </div>
                    </div>

                </div>
                <div style="clear:both;"></div>
                <div class="AppleRedPacket_box">
                    <span>截止时间</span>
                    <CSSelect height="40px" i-width="36px">
                        <el-date-picker
                            class="timers"
                            v-model="addRedPacketParams.expiredTime"
                            type="datetime"
                            style="font-size: 24px; width: 300px; line-height: 32px"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                            placeholder="请输入"
                            :editable="false"
                        >
                        </el-date-picker>
                    </CSSelect>
                </div>
                <div class="AppleRedPacket_box">
                    <span style="vertical-align: top">备注</span
                    ><i>
                        <textarea
                            v-model="addRedPacketParams.comment"
                            style="
                                width: 720px;
                                height: 200px;
                                border: none;
                                outline: none;
                                padding: 10px;
                                white-space: normal;
                                word-break: break-all;
                                word-wrap: break-word;
                                border-radius: 6px;
                                background: #efeff0;
                                resize: none;
                            "
                            placeholder="限50个字"
                        ></textarea>
                </i>
                </div>
            </div>
        </CSDialog>
        <!-- 修改苹果红包活动状态 -->
        <CSDialog
            dialogTitle="提示" dialog-confirm-btn-text="确定"
            dialogWidth="480px" dialog-header-class=" "
            :dialogVisible="changeRedPacketActivityState.visible"
            @onClose="changeRedPacketActivityState.visible = false"
            @onConfirm="changeRedPacketActivityState.onOk"
            dialog-header-icon="icon-menua-zu13"
        >
            <div
                slot="dialog-content"
                style="padding: 20px 30px; font-size: 24px; text-align: center;"
            >
                <!--                确定二维码{{activity.state === 3 ? '生效' : '失效' }}吗?-->
                {{ changeRedPacketActivityState.title }}？
            </div>
        </CSDialog>
        <!-- <el-dialog
            :visible.sync="changeRedPacketActivityState.visible"
            width="700px"
        >
            <div
                style="margin: 20px auto; font-size: 24px; font-weight: bold"
                class="text-center"
            >
                确定操作？
            </div>
            <div
                class="text-center"
                style="font-size: 24px; margin-bottom: 20px"
            >
                {{ changeRedPacketActivityState.title }}
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button
                    style="padding: 0"
                    @click="changeRedPacketActivityState.onCancel"
                    class="cancel_"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    style="padding: 0"
                    class="determine"
                    @click="changeRedPacketActivityState.onOk"
                    >确 定</el-button
                >
            </div>
        </el-dialog> -->
    </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import CSRadio from "@/components/common/CSRadio";
import ChooseLocation from "@/components/ChooseLocation";
import {
    createAppleRedPackUrl,
    queryAppleRedPackUrl,
    updateAppleRedPacketStateUrl,
    downloadFileOssDomain,
} from "@/requestUrl";
import dayjs from "dayjs";
import ViewModal from "@/components/ViewModal";
import CSTable from "@/components/common/CSTable";

export default {
    name: "AppleRedPacket",
    props: {},
    components: {
        CSTable,
        ViewModal,
        Pagination,
        CSSelect,
        CSDialog,
        CSRadio,
        ChooseLocation
    },
    data() {
        return {
            isSubmitting: false,
            downloadFileOssDomain,
            filterHeight: 0,
            createAppleRedPacketVisible: false, //控制添加苹果红包的弹出框
            // 红包状态
            appleStateMapping: {
                1: "已创建",
                2: "发放中",
                3: "已停止",
            },
            //  改变红包活动状态
            changeRedPacketActivityState: {
                visible: false,
                title: "",
                data: {
                    id: "",
                    enable: true,
                },
                onOk: () => {
                    this.postChangeAcitivity(
                        this.changeRedPacketActivityState.data
                    );
                },
                onCancel: () => {
                    this.changeRedPacketActivityState.visible = false;
                },
            },
            queryParams: {
                regionCode: this.$vc.getCurrentRegion().code,
                state: "", // 存放选择的红包状态
                keyword: "",
                //   开始时间
                timeStart:
                    dayjs().subtract(30, "day").format("YYYY-MM-DD") +
                    " 00:00",

                timeEnd: dayjs().format("YYYY-MM-DD") + " 23:59", //结束时间   必填
                manner:"",
                type:""

            },
            appleRedpacketActivities: [],
            //   添加苹果红包里的数据
            addRedPacketParams: {
                regionCode: this.$vc.getCurrentRegion().code,
                name: "",
                amount: "",
                shares: "",
                comment: "",
                expiredTime: "",
                manner:1,
                type:1,
                userId:""

            },
            defaultLocationName:""
        };
    },
    created() {
        window.addEventListener("keydown",this.getAppleRedPacketListDown);
        this.getAppleRedPacketList();
        this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
            this.getAppleRedPacketList(_currentPage);
        });
    },
    beforeDestroy(){
        window.removeEventListener("keydown",this.getAppleRedPacketListDown);
    },
    deactivated(){
        window.removeEventListener("keydown",this.getAppleRedPacketListDown);
    },
    mounted() {
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        })
    },
    methods: {
        getAppleRedPacketListDown(e){
            if(e.keyCode == 13){
                this.getAppleRedPacketList();
            }
        },
        lookComment(comment) {
            this.$vc.emit(this.$route.path, "viewModal", "openViewModal", {
                title: '备注',
                content: comment
            });
        },
        /**
         * 修改红包活动
         * @param {Object} data 后端传参需要的参数
         *  */
        postChangeAcitivity(data) {
            this.$fly.post(updateAppleRedPacketStateUrl, data).then((res) => {
                if (res.code !== 0) {
                    return;
                }
                this.$vc.toast("更改完成");
                this.changeRedPacketActivityState.visible = false;
                this.getAppleRedPacketList();
            });
        },
        /**
         *  打开修改红包活动确认弹窗
         * @param {Object} activity 红包活动信息
         *  */
        openChangeActivityDialog(activity) {
            this.changeRedPacketActivityState.title = `确认要让二维码${
                activity.state === 3 ? "生效" : "失效"
            }吗`;
            this.changeRedPacketActivityState.visible = true;
            this.changeRedPacketActivityState.data = {
                id: activity.id,
                enable: activity.state === 3,
            };
        },
        //  打开创建红包弹窗
        openCreateAppleRedPacketDialog() {
            this.createAppleRedPacketVisible = true;
            this.addRedPacketParams = {
                regionCode: this.$vc.getCurrentRegion().code,
                name: "",
                amount: "",
                shares: "",
                comment: "",
                expiredTime: "",
                manner:1,
                type:1,
                userId:""
            };
        },
        //  创建苹果红包
        createAppleRedPacket() {
            console.log(this.addRedPacketParams)
            if (this.addRedPacketParams.type == 2){
                this.addRedPacketParams.shares = 1;
                this.addRedPacketParams.manner = 3;
            }
            this.isSubmitting = true;
            this.$fly
                .post(createAppleRedPackUrl, this.addRedPacketParams)
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.createAppleRedPacketVisible = false;
                    this.$vc.toast("创建成功");
                    this.getAppleRedPacketList();
                })
                .finally(() => this.isSubmitting = false)
        },
        //  获取苹果红包数据
        getAppleRedPacketList(pageNo = 1, pageSize = 10) {
            this.$fly
                .post(queryAppleRedPackUrl, {
                    ...this.queryParams,
                    timeStart: this.queryParams.timeStart + ':00',
                    timeEnd: this.queryParams.timeEnd + ':59',
                    pageNo,
                    pageSize,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.appleRedpacketActivities = res.data.datas;
                    if (pageNo === 1) {
                        this.$vc.emit(this.$route.path, "pagination", "init", {
                            total: res.data.total || res.data.datas.length,
                            pageSize: res.data.total ? pageSize : res.data.datas.length,
                            pageNo,
                        })
                    }
                    // TODO:页数待修改;
                });
        },
        //获取用户ID
        changeLocation(e) {
            this.addRedPacketParams.userId = e.id;
        },
    },
};
</script>
<style scoped>
#FoundAppleRedPacket .el-dialog__header {
    /* margin: 0; */
    padding: 0;
    height: 0;
}

#FoundAppleRedPacket .el-dialog__body {
    padding: 5px 0 0 30px;
    color: #000000;
}

#FoundAppleRedPacket .el-dialog__footer {
    border-top: 1px solid #f0f0f0;
    text-align: center !important;
    padding-top: 20px;
}

#FoundAppleRedPacket .el-upload--picture-card {
    position: relative;
    left: 350px;
    top: -90px;
    width: 80px !important;
    height: 80px !important;
    display: inline-block;
}

img {
    position: relative;
    margin-right: 20px;
}

.aa .el-dialog__body,
.aa .el-dialog {
    padding: 0;
    background: none;
    box-shadow: none;
}

.amendstyle .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 130px;
}

.cs-style input[type="checkbox"] {
    height: 16px;
}

.AppleRedPacket_box .el-input--prefix .el-input__inner::placeholder{
    color: #999;
}
</style>

<style scoped>
.el-dialog .upload-photo,
.el-dialog .room-photo {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-right: 10px;
    margin-top: 0 !important;
}

.room-photo {
    border: 1px solid #999999;
}

.room-photo img {
    width: 100%;
    height: 100%;
}

.upload-photo {
    text-align: center;
    border: 1px dashed #999999;
    font-size: 14px;
    margin-bottom: 0;
}

.upload-photo div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
}

.upload-photo img {
    width: 34px;
    height: 27px;
    margin-bottom: 13px;
}

.el-table-column {
    text-align: center;
}

i {
    font-style: normal;
}

.el-tabs {
    margin: 20px 0;
}

.btn-secondary {
    margin: 20px 0;
}

.el-table {
    text-align: center;
}

.el-table-column {
    text-align: center;
}

.form-group {
    display: inline;
    margin-right: 20px;
}

.el-card {
    margin-bottom: 20px;
}

.line {
    margin-right: 20px;
}

.form-tip {
    margin-left: 10px;
}

.el-dialog span {
    margin-right: 20px;
}

/* .el-dialog div {
    margin: 10px 0;
} */
.el-dialog .remarks {
    margin-left: 14px;
}

.col-lg-12 button {
    border: 0;
}

.item {
    position: relative;
    left: 55px;
    top: -10px;
}

.el-button:hover {
    color: #6a8bf6;
    text-decoration: underline;
}

.success {
    width: 50px;
    height: 30px;
    border-radius: 5px;
    background-color: #18a689;
    color: #fff;
    font-size: 14px;
    line-height: 30px;
}

.adddialog {
    width: 120px;
    height: 30px;
    line-height: 5px;
    background-color: #1ab394;
    margin: 15px 30px 15px 0;
}

.adddialog:hover {
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
}

.typeinput {
    width: 220px;
    display: inline;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 2px;
}

.form-group span {
    margin-right: 10px;
}

.dropdown-menu a {
    z-index: 99999;
    display: block;
}

.dropdown-menu {
    z-index: 99999 !important;
}

.table {
    text-align: center !important;
}

.el-card__body {
    padding: 0 !important;
}

input {
    width: 200px;
    height: 30px;
}

.inputwidth input {
    width: 80px;
}

.mb-15 {
    margin-bottom: 15 rpx;
}

.el-icon-camera {
    position: absolute;
    top: 10px;
    left: 28px;
    font-size: 20px;
}

.uploadPictures {
    position: absolute;
    width: 70px;
    top: -25px;
    left: 6px;
    overflow: hidden;
}

.line {
    margin: 0 20px;
}

.iconclose {
    display: inline-block;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px;
    height: 20px;
    background: url(../../assets/delete.png) no-repeat;
    background-size: 80%;
}

.phoneBackground {
    background: url(../../assets/iphone6.png) no-repeat;
    width: 500px;
    height: 850px;
    background-size: 100% 100%;
    background-position: center center;
    margin: 0 auto;
}

.contentarea {
    position: absolute;
    top: 95px;
    left: 30px;
    width: 442px;
    height: 640px;
    background-color: #fff;
}

.price_ input {
    width: 65px;
}

/* input 单选框样式 */
input[type="radio"] {
    width: 20px;
    height: 16px;
    cursor: pointer;
    position: relative;
}

input[type="radio"]:after {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    content: " ";
    background-color: #fff;
    color: #fff;
    display: inline-block;
    visibility: visible;
    border-radius: 3px;
    border: 1px solid #999999;
    font-weight: bold;
    text-align: center;
}

input[type="radio"]:checked:after {
    content: "✓";
    font-size: 13px;
    background-color: #1ab394;
}

.priceBox {
    padding-left: 150px;
}

.bb .leftFloat {
    margin-left: 135px;
}

.inputSpace {
    margin-left: 5px !important;
}

.typeinput {
    border: 1px solid #999;
}

.yellow_ {
    width: 80px;
    height: 40px;
    background: #ff9f00;
    box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
    border-radius: 6px;
    border: none;
    color: #fff;
    margin-right: 20px;
}

select {
    border-radius: 3px;
    border: 1px solid #999;
}

table a {
    text-decoration: underline;
}

.cancel_ {
    width: 80px;
    height: 40px;
    background: #ff9f00;
    box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
    border-radius: 6px;
    color: #fff;
    font-size: 24px;
    border: none;
}

.determine {
    width: 80px;
    height: 40px;
    background: #1ab394;
    box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
    border-radius: 6px;
    color: #fff;
    border: none;
    font-size: 24px;
    margin-left: 60px;
}

.AppleRedPacket_box {
    font-size: 24px;
    margin-bottom: 15px;
}

.AppleRedPacket_box input {
    width: 400px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #999;
    padding-left: 5px;
}

.AppleRedPacket_box span {
    display: inline-block;
    width: 144px;
    text-align: right;
    margin-right: 30px;
}

.add-redpacket-header {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin: 20px auto;
}
.apple-Red{
    position: absolute;
    right: 10px;
}
.apply-btn{
    position: relative;
}
</style>
